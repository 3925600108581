// Urls, the happy collection
const refs = {
  dataStocks: 'https://dnul.stuke.no/stocks.json',
  dataCurrency: 'https://data.norges-bank.no/api/data/EXR/B.USD+EUR.NOK.SP?format=sdmx-json&lastNObservations=1&locale=no',
  // For debugging change refs in getData to this dataUrl3
  dataStocksNow: '/now/stocks.json',
  // For 1 day old stocks.json file
  dataStocks1do: './before/1stocks.json',
  // For 2 day old ...
  dataStocks2do: './before/2stocks.json',
  // additional market information
  dataStocksExtra: '/now/ExtraStockInfo.json',
  // Github api
  dataGithub: 'https://api.github.com/users/toremann/repos',
};

export function getStockData() {
  return fetch(refs.dataStocks, { method: 'GET' })
    .then((res) =>
      res.json().then((json) => ({
        lastModified: res.headers.get('Last-Modified', new Date()),
        data: json,
      }))
    )
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export function getStockExtraData() {
  return fetch(refs.dataStocksExtra, { method: 'GET' })
    .then((res) =>
      res.json().then((json) => ({
        data: json,
      }))
    )
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export function getCurrency() {
  return fetch(refs.dataCurrency, { method: 'GET' })
    .then((response) => response.json())
    .then((result) => ({
      content: result,
    }))
    .catch((error) => console.log('error', error));
}
