import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';

require('dotenv').config({ debug: process.env.DEBUG });

const PortfolioHead = () => {
  return (
    <thead className="portfolio_table">
      <th>Aksje</th>
      <th>Antall</th>
      <th></th>
      <th></th>
    </thead>
  );
};

const Portfolio = () => {
  const [newStock, setNewStock] = useState('');
  const [newQuantity, setNewQuantity] = useState('0');

  const [stocks, setStocks] = useState([]);
  const stocksCollectionRef = collection(db, 'portfolio');

  const createStock = async () => {
    await addDoc(stocksCollectionRef, { stock: newStock, quantity: Number(newQuantity) });
  };

  const updateStock = async (id, quantity) => {
    const stockDoc = doc(db, 'portfolio', id);
    const newFields = { quantity: quantity + 1 };
    await updateDoc(stockDoc, newFields);
  };

  const deleteStock = async (id) => {
    const stockDoc = doc(db, 'portfolio', id);
    await deleteDoc(stockDoc);
  };

  useEffect(() => {
    const getStocks = async () => {
      const data = await getDocs(stocksCollectionRef);
      setStocks(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    getStocks();
    // }, [stocks]); passing prop will drain database reads
  }, []);

  const { user, isAuthenticated } = useAuth0();

  return (
    isAuthenticated && (
      <div className="stocks-extra">
        <div className="stocksextra-top">
          <span className="stock_symbol_extra">Portfolio</span>
          <br />
          <span className="stock_long_name_extra">User {user.stock}</span>
          <br />
          <span className="stock_price_extra">Dummy text</span>
        </div>
        <div className="profil">
          <h2>Portfolio</h2>

          <div class="portfolio">
            <div class="pdiv1">Neste utbytte</div>
            <div class="pdiv2">Analyse</div>
            <div class="pdiv3">
              <div>
                <input
                  placeholder="TICKER..."
                  onChange={(event) => {
                    setNewStock(event.target.value);
                  }}
                />
              </div>
              <div>
                <input
                  min="1"
                  type="number"
                  placeholder="ANTALL..."
                  onChange={(event) => {
                    setNewQuantity(event.target.value);
                  }}
                />
              </div>
              <div>
                <button onClick={createStock}>Legg til aksje </button>
              </div>
              <PortfolioHead />
              {stocks.map((stocks) => {
                return (
                  <div>
                    <table className="portfolio_table">
                      {/* <thead>
                        <th>Aksje</th>
                        <th>Antall</th>
                      </thead> */}

                      <tbody>
                        <tr>
                          <td>{stocks.stock} </td>
                          <td>
                            <input value={stocks.quantity} onChange={() => updateStock(stocks.id, stocks.quantity)} />
                          </td>
                          <td>
                            <button
                              onClick={() => {
                                updateStock(stocks.id, stocks.quantity);
                              }}
                            >
                              Increase quantity
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={() => {
                                deleteStock(stocks.id);
                              }}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <br />
        <Link exact to="/">
          <FaArrowLeft size="30" />
        </Link>
      </div>
    )
  );
};

export default Portfolio;
