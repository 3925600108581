export const convertCurrency = function (stock, currencies) {
  if (currencies[stock.company_info.dividend_currency]) {
    return `${roundToDecimal(stock.company_info.dividend_amount * currencies[stock.company_info.dividend_currency], 2)} NOK`;
  } else {
    return `${stock.company_info.dividend_amount} ${stock.company_info.dividend_currency}`;
  }
};

export const roundToDecimal = function (val, decimalPoints) {
  let decimalPow = Math.pow(10, decimalPoints);
  return Math.round(val * decimalPow) / decimalPow;
};

export const findMarket = function (stock, stocksExtraData) {
  if (stock.instrument_info.isin === stocksExtraData.data.isin) {
    return stocksExtraData.data.market;
  } else {
    console.log('Error in findMarket');
  }
};

export const findUtbyttefrekvens = function (stock, stocksExtraData) {
  if (stock.instrument_info.isin === stocksExtraData.data.isin) {
    return stocksExtraData.data.utbyttefrekvens;
  } else {
    console.log('Error in findUtbyttefrekvens');
  }
};
