/* Stylesheet */
import './App.css';
/* Components */
import ReactGA from 'react-ga';
import Status from './components/status';
import Credits from './components/credits';
import Footer from './components/footer';
import Join from './components/join';
import Stocks from './components/stocks';
import Header from './components/header';
import StocksExtra from './components/stocksExtra';
import '@fontsource/roboto';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

/* API import */
import { getStockData, getCurrency, getStockExtraData } from './components/api';
import React, { useState, useEffect } from 'react';
import Profile from './components/profile';
import Portfolio from './components/portfolio';

require('dotenv').config({ debug: process.env.DEBUG });

ReactGA.initialize('UA-206081963-1', { debug: false });
ReactGA.pageview(window.location.pathname + window.location.search);

export const DataContext = React.createContext();
export const DataExtraContext = React.createContext();

function App() {
  const [stockData, setStockData] = useState({
    data: [],
    serverstatus: 'OFFLINE',
    totalScraped: 0,
    totalHits: 0,
    lastModified: new Date(),
  });

  const [currencyData, setCurrencyData] = useState({
    lastModifiedCurrency: new Date(),
    dollarCurrency: 0,
    euroCurrency: 0,
    USD: 0,
    EUR: 0,
  });

  const [stockExtraData, setStockExtraData] = useState({
    data: [],
  });

  useEffect(() => {
    getStockData()
      .then((res) => {
        // FILTER OUT UNWANTED STOCKS
        const filteredData = res.data.filter((stock) => {
          return Boolean(stock.key_ratios_info && stock.key_ratios_info.hasOwnProperty('dividend_yield') && stock.company_info && stock.company_info.hasOwnProperty('dividend_amount'));
        });

        setStockData({
          data: filteredData,
          totalScraped: res.data.length,
          totalHits: filteredData.length,
          lastModified: res.lastModified,
          serverstatus: 'ONLINE',
        });
      })
      .catch((err) => {
        console.error(err);
        setStockData({
          data: [],
          serverstatus: 'OFFLINE',
        });
      });

    getStockExtraData().then((res) => {
      const stockMarket = res.data.extra;
      setStockExtraData({
        data: stockMarket,
      });
    });

    getCurrency()
      .then((result) => {
        const currencyNB = result.content;
        const currencyModified = currencyNB.meta.prepared;
        const dollarCurrency = currencyNB.data.dataSets[0].series['0:1:0:0'].observations[0][0];
        const euroCurrency = currencyNB.data.dataSets[0].series['0:0:0:0'].observations[0][0];
        setCurrencyData({
          lastModifiedCurrency: currencyModified,
          dollarCurrency: dollarCurrency,
          euroCurrency: euroCurrency,
          USD: dollarCurrency,
          EUR: euroCurrency,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          data: [],
          serverstatus: 'OFFLINE',
        });
      });
  }, []);
  // console.log('StockExtra:', stockExtraData.data);
  // console.log(JSON.stringify(stockExtraData));
  // console.log(stockExtraData.extra.isin);
  // console.log(JSON.stringify(stockExtraData, undefined, 4));

  return (
    <Router basename="/">
      <DataContext.Provider value={stockData.data}>
        <DataExtraContext.Provider value={stockExtraData.data}>
          <div className="container">
            <Header />
            <div className="content-container">
              <Switch>
                <Route exact path="/">
                  <Stocks stocks={stockData} currencies={currencyData} />
                </Route>
                <Route path="/stocksextra/:ticker">
                  <StocksExtra stocks={stockData} currencies={currencyData} stockExtra={stockExtraData} />
                </Route>
                <Route path="/profile/">
                  <Profile />
                </Route>
                <Route path="/portfolio/">
                  <Portfolio />
                </Route>
              </Switch>
            </div>
            <Status stocks={stockData} currencies={currencyData} />
            <Join />
            <Credits />
            <Footer />
          </div>
        </DataExtraContext.Provider>
      </DataContext.Provider>
    </Router>
  );
}

export default App;
