import React from 'react';
import { FaDollarSign, FaEuroSign } from 'react-icons/fa';

// STOCKS
const Status = ({ stocks, currencies }) => {
  return (
    <div className="status">
      <div className="thumbnail">
        <h1 className="status_text">
          <div id="totalt_skrapet">{stocks.totalScraped}</div>
        </h1>
        <h4>TOTALT SKRAPET</h4>
      </div>
      <div className="thumbnail">
        <h1 className="status_text">
          <div id="utbytte_aksjer">{stocks.totalHits}</div>
        </h1>
        <h4>UTBYTTE AKSJER</h4>
      </div>
      <div className="thumbnail">
        <h1 className="status_text">
          <div id="aksje_sist_oppdatert">{new Date(stocks.lastModified).toLocaleDateString('en-GB')}</div>
        </h1>
        <h4>AKSJE OPPDATERT</h4>
      </div>
      <div className="thumbnail">
        <h1 className="status_text">
          <div id="valuta_sist_oppdatert">{new Date(currencies.lastModifiedCurrency).toLocaleDateString('en-GB')}</div>
        </h1>
        <h4>VALUTA OPPDATERT</h4>
        <div className="thumbnail_extra">
          <FaDollarSign />
          {Number(currencies.dollarCurrency).toFixed(2)} <FaEuroSign />
          {Number(currencies.euroCurrency).toFixed(2)}
        </div>
      </div>
      <div className="thumbnail">
        <h1 className="status_text">
          <div id="server_status">{stocks.serverstatus}</div>
        </h1>
        <h4>SERVER STATUS</h4>
      </div>
    </div>
  );
};

export default Status;
