// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from '@firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD-vZHbCiAIkcTLJwvKbF66XM0_PEK_PcY',
  authDomain: 'faavne-test.firebaseapp.com',
  projectId: 'faavne-test',
  storageBucket: 'faavne-test.appspot.com',
  messagingSenderId: '540755795755',
  appId: '1:540755795755:web:0cddd674bf6e1c85772f6e',
  measurementId: 'G-PYQYTJZNY1',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const db = getFirestore(app);
