import React, { useContext, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaFacebookMessenger, FaFacebookF, FaTwitter, FaLinkedinIn, FaRedditAlien, FaArrowLeft, FaEnvelope } from 'react-icons/fa';
import { DataContext, DataExtraContext } from '../App';
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, RedditShareButton, TwitterShareButton, FacebookMessengerShareButton } from 'react-share';
import { convertCurrency } from './functions';

const StocksExtra = ({ stocks, currencies, stockExtraData }) => {
  const { ticker } = useParams();
  const stockData = useContext(DataContext);
  const stockExtra = useContext(DataExtraContext);
  const [myInput, newInput] = useState(1);
  const stock = stockData && stockData.find((stock) => stock && stock.instrument_info && stock.instrument_info.symbol === ticker);
  if (!stock) {
    return (
      <div className="spinner">
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }

  let stuff;
  const webTitle = 'Faavne.no';
  const webTicker = ticker;
  const webDivAmount = stock && stock.company_info.dividend_amount;
  const webDivAmountCur = stock && stock.company_info.dividend_currency;
  const title = `${webTitle} | Ticker: ${webTicker} Utbytte: ${webDivAmount} ${webDivAmountCur}`;
  const shareUrl = window.location.href;

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const dividendDate = new Date(stock.company_info.dividend_date);
  const todayDate = new Date();
  const diffDaysDividend = Math.round(Math.abs((todayDate - dividendDate) / oneDay));
  const exDate = new Date(stock.company_info.excluding_date);
  const exPassert = 'EX- dato har passert!';
  const diffDaysExPassed = exPassert;
  const exDagerUtlop = 'EX- utløper om ';
  const exDager = ' dager.';
  const exDag = ' dag.';
  const diffDaysEx = Math.round((exDate - todayDate) / oneDay);

  function CheckDiffDaysEx() {
    if (diffDaysEx === 1) {
      return exDagerUtlop + diffDaysEx + exDag;
    }
    if (diffDaysEx <= 0) {
      return diffDaysExPassed;
    } else {
      return exDagerUtlop + (diffDaysEx + 1) + exDager;
    }
  }

  if (stock && stock.instrument_info && stockExtra) {
    const theStockExtraIwant = stockExtra.find((extra) => extra.isin === stock.instrument_info.isin);
    if (theStockExtraIwant) {
      const { name, isin, symbol, ...theRestOfMyShit } = theStockExtraIwant;
      stuff = theRestOfMyShit;
      console.log(stuff);
    }
  }

  return (
    <div className="stocks-extra" key={stockData.serverstatus}>
      {stock && stock.instrument_info ? (
        <>
          <div className="stocksextra-top">
            <div>
              <span className="stock_symbol_extra">{stock.instrument_info.symbol}</span>
              <br />
              <span className="stock_long_name_extra">{stock.instrument_info.long_name}</span>
              <br />
              <span className="stock_price_extra">Sist oppdatert: {new Date(stocks.lastModified).toLocaleDateString('en-GB')}</span>
            </div>
          </div>
          <div className="stocks-extra-grid">
            <div className="extra-1">
              <b>PRISER:</b> <br />
              Pris: {Number(stock.price_info.last.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {stock.instrument_info.currency}
              <br />
              Open: {Number(stock.price_info.open.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {stock.instrument_info.currency}
              <br />
              Close: {Number(stock.price_info.close.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {stock.instrument_info.currency}
            </div>
            <div className="extra-2">
              <b>DATOER:</b> <br />
              <table>
                <tbody>
                  <tr>
                    <td>Utbytte dato:</td>
                    <td>{new Date(stock.company_info.dividend_date).toLocaleDateString('en-GB')}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Utbytte om {diffDaysDividend} dager.</td>
                  </tr>
                  <tr>
                    <td>EX- dato:</td>
                    <td>{new Date(stock.company_info.excluding_date).toLocaleDateString('en-GB')}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>{CheckDiffDaysEx()}</td>
                  </tr>
                  <tr>
                    <td>Generalforsamling:</td>
                    <td>{new Date(stock.company_info.general_meeting_date).toLocaleDateString('en-GB')}</td>
                  </tr>
                  <tr>
                    <td>Rapport dato:</td>
                    <td>{new Date(stock.company_info.report_date).toLocaleDateString('en-GB')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="extra-3">
              <b>UTBYTTE:</b> <br />
              Utbytte: {convertCurrency(stock, currencies)}
              <br />
              Valuta: {stock.company_info.dividend_currency} <br />
            </div>
            <div className="extra-4">hidden</div>
            <div className="extra-5">
              <b>KALKULATOR:</b>
              <br />
              Antall aksjer:
              <br />
              <input className="kalkulator" placeholder="Antall aksjer" name="antall_aksjer" type="number" min="1" value={myInput} onChange={(e) => newInput(e.target.value)} />
              <br />
              Pris: {Number(stock.price_info.last.price * myInput).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} NOK <br />
              Utbytte: {Number(stock.company_info.dividend_amount * myInput).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {stock.company_info.dividend_currency}
            </div>
            <div className="extra-6">
              <b>YIELD HISTORIE:</b> <br />
              <table>
                <tbody>
                  <tr>
                    <td>1d:</td>
                    <td>{stock.historical_returns_info.yield_1d} </td>
                  </tr>
                  <tr>
                    <td>1w:</td>
                    <td>{stock.historical_returns_info.yield_1w}</td>
                  </tr>
                  <tr>
                    <td>1m:</td>
                    <td>{stock.historical_returns_info.yield_1m}</td>
                  </tr>
                  <tr>
                    <td>3m:</td>
                    <td>{stock.historical_returns_info.yield_3m}</td>
                  </tr>
                  <tr>
                    <td>1y:</td>
                    <td>{stock.historical_returns_info.yield_1y}</td>
                  </tr>
                  <tr>
                    <td>3y:</td>
                    <td>{stock.historical_returns_info.yield_3y}</td>
                  </tr>
                  <tr>
                    <td>5y:</td>
                    <td>{stock.historical_returns_info.yield_5y}</td>
                  </tr>
                  <tr>
                    <td>ytd:</td>
                    <td>{stock.historical_returns_info.yield_ytd}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="extra-7">
              <b>TILEGG:</b> <br />
              Marked: {stuff && stuff.market || 'Loading'}
              <br />
              Utbyttefrekvens: {stuff && stuff.utbyttefrekvens || 'Loading'}
              <br />
              Sektor: {stuff && stuff.sektor || 'Loading'}
              <br />
              Bransje: {stuff && stuff.bransje || 'Loading'}
              <br />
              Yield: {parseFloat(stock.key_ratios_info.dividend_yield)}%
            </div>
            <div className="extra-8">div 8</div>
            <div className="stocksextra-footer">
              <span>
                <FacebookShareButton url={shareUrl} quote={title}>
                  <FaFacebookF size="20" />
                </FacebookShareButton>
              </span>
              <span>
                <FacebookMessengerShareButton url={shareUrl} appId="521270401588372">
                  <FaFacebookMessenger size="20" />
                </FacebookMessengerShareButton>
              </span>
              <span>
                <TwitterShareButton url={shareUrl} title={title}>
                  <FaTwitter size="20" />
                </TwitterShareButton>
              </span>
              <span>
                <LinkedinShareButton url={shareUrl}>
                  <FaLinkedinIn size="20" />
                </LinkedinShareButton>
              </span>
              <span>
                <RedditShareButton url={shareUrl} title={title} windowWidth={660} windowHeight={460}>
                  <FaRedditAlien size="20" />
                </RedditShareButton>
              </span>
              <span>
                <EmailShareButton url={shareUrl} subject={title} body="body">
                  <FaEnvelope size="20" />
                </EmailShareButton>
              </span>
            </div>
          </div>
        </>
      ) : (
        <span>Kan ikke finne aksje data for {ticker}</span>
      )}
      <Link exact to="/">
        <FaArrowLeft size="30" />
      </Link>
    </div>
  );
};

export default StocksExtra;
