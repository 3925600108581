import React from 'react';
import Dnul from '../images/dnul.png';
import Codev from '../images/codev.png';
import Lassendev from '../images/lassendev.png';
import { AiFillGithub, AiFillInstagram, AiFillFacebook, AiFillTwitterCircle } from 'react-icons/ai';
import { ExternalLink } from 'react-external-link';

const credits = () => {
  return (
    <div className="credits">
      <div className="credits_left">
        <span>
          <ExternalLink href="https://github.com/toremann">
            <AiFillGithub size="1.5rem" />
          </ExternalLink>
        </span>
        <span>
          <ExternalLink href="https://www.instagram.com/faavne/">
            <AiFillInstagram size="1.5rem" />
          </ExternalLink>
        </span>
        <span>
          <ExternalLink href="https://www.facebook.com/faavne">
            <AiFillFacebook size="1.5rem" />
          </ExternalLink>
        </span>
        <span>
          <ExternalLink href="https://www.twitter.com">
            <AiFillTwitterCircle size="1.5rem" />
          </ExternalLink>
        </span>
      </div>
      <div className="credits_right">
        <ExternalLink href="https://www.dnul.no">
          <img src={Dnul} alt="dnul" />
        </ExternalLink>
        <ExternalLink href="http://www.codev.no">
          <img src={Codev} alt="codev" />
        </ExternalLink>
        <ExternalLink href="https://www.lassen.dev">
          <img src={Lassendev} alt="So slow, might be upside down." />
        </ExternalLink>
      </div>
    </div>
  );
};

export default credits;
