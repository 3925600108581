import { BsArrowUpShort, BsArrowDownShort, BsArrowRightShort } from 'react-icons/bs';
import { convertCurrency } from './functions';
import { Link } from 'react-router-dom';

const Stocks = (props) => {
  const { stocks, currencies } = props;
  return (
    <div>
      <div className="stocks">
        <h3>UTBYTTE AKSJER:</h3>
        <table className="stocks-table">
          <thead>
            <tr>
              <th>RANK</th>
              <th>NAVN</th>
              <th>PRIS</th>
              <th>UTBYTTE</th>
              <th>YIELD</th>
              <th>EKS-DATO</th>
              <th>UTBYTTE DATO</th>
            </tr>
          </thead>
          <tbody>
            {stocks.data
              .sort((a, b) => (a.key_ratios_info.dividend_yield < b.key_ratios_info.dividend_yield ? 1 : -1))
              .map((stock) => {
                return {
                  ...stock,
                  hasPassed: new Date(stock.company_info.excluding_date) < new Date().getTime(),
                  nanErr: !stock.price_info.hasOwnProperty('diff_pct'),
                  hasUp: stock.price_info.diff_pct > 0,
                  hasDown: stock.price_info.diff_pct < 0,
                  noChange: stock.price_info.diff_pct === 0,
                };
              })

              .map((stock, index) => (
                <StockRow key={index} stock={stock} index={index} currencies={currencies} />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const StockRow = ({ stock, index, currencies }) => {
  return (
    <>
      <tr key={index.id}>
        <td>
          <span className="td_rank">{index + 1}</span>
        </td>
        <td>
          <span className="stock_symbol">
            <Link to={`/stocksextra/${stock.instrument_info.symbol}`}>{stock.instrument_info.symbol}</Link>
          </span>
          <br /> <span className="stock_long_name">{stock.instrument_info.long_name}</span>
        </td>
        <td>
          <h1 className="percentchange" style={{ color: stock.hasUp ? 'green' : stock.hasDown ? 'red' : 'orange' }}>
            {stock.hasUp ? <BsArrowUpShort /> : null}
            {stock.hasDown ? <BsArrowDownShort /> : null}
            {stock.noChange ? <BsArrowRightShort /> : null}
            {stock.nanErr ? <BsArrowRightShort /> : null}
            {parseFloat(stock.price_info.diff_pct || 0)}%
          </h1>
          {Number(stock.price_info.last.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {stock.instrument_info.currency}
        </td>

        <td>
          <span>{convertCurrency(stock, currencies)} </span>
        </td>
        <td>{parseFloat(stock.key_ratios_info.dividend_yield)}%</td>
        <td style={{ color: stock.hasPassed ? 'Red' : 'initial', textDecorationLine: stock.hasPassed ? 'line-through' : 'initial' }}>
          {new Date(stock.company_info.excluding_date).toLocaleDateString('en-GB')}
        </td>
        <td>{new Date(stock.company_info.dividend_date).toLocaleDateString('en-GB')}</td>
      </tr>
    </>
  );
};

export default Stocks;
