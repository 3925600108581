import MailchimpSubscribe from 'react-mailchimp-subscribe';

// require('dotenv').config({ debug: process.env.DEBUG })
// const MAILCHIMP_URL = process.env.REACT_APP_MAILCHIMP_URL;

const SimpleForm = () => <MailchimpSubscribe url={process.env.REACT_APP_MAILCHIMP_URL} />;

const CustomForm = () => (
  <MailchimpSubscribe
    url={process.env.REACT_APP_MAILCHIMP_URL}
    render={({ subscribe, status, message }) => (
      <div>
        <SimpleForm onSubmitted={(formData) => subscribe(formData)} />
        {status === 'sending' && <div style={{ color: 'white' }}>sending...</div>}
        {status === 'error' && <div style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: message }} />}
        {status === 'success' && <div style={{ color: 'white' }}>Subscribed !</div>}
      </div>
    )}
  />
);

const join = () => {
  return (
    <div className="join">
      <div>
        <p>FÅ UTBYTTE RANGERING TILSENDT PÅ EPOST</p>
      </div>
      <div>
        <CustomForm />
      </div>
    </div>
  );
};

export default join;
