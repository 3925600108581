import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

const Profile = () => {
  const { user, isAuthenticated } = useAuth0();

  return (
    isAuthenticated && (
      <div className="stocks-extra">
        <div className="stocksextra-top">
          <span className="stock_symbol_extra">Dashboard</span>
          <br />
          <span className="stock_long_name_extra">{user.name}</span>
          <br />
          <span className="stock_price_extra">Sist logget inn: {new Date(user.updated_at).toLocaleDateString('en-GB')}</span>
        </div>
        <div className="profil">
          <h2>Innstillinger</h2>
          <br />
          <b>DATA FRA AUTH0</b> <br />
          given name: {user.given_name} <br />
          nickname: {user.nickname} <br />
          name: {user.name} <br />
          locale: {user.locale} <br />
          updated at: {user.updated_at} <br />
          email: {user.email} <br />
          email verified: {user.email_verified} <br />
          UNIK BRUKER ID: <b>{user.sub}</b> (LINK MED DB)
          <br />
        </div>
        <br />
        <Link exact to="/">
          <FaArrowLeft size="30" />
        </Link>
      </div>
    )
  );
};

export default Profile;
