import React from 'react';
// import Switches from './switches';
// import { useStore } from '../states/useStore';
import { Link } from 'react-router-dom';
import LoginButton from './loginButton';
import LogoutButton from './logoutButton';
import { useAuth0 } from '@auth0/auth0-react';

const Profile = () => {
  const { isAuthenticated } = useAuth0();
  return (
    isAuthenticated && (
      <Link exact to="/profile/">
        Profil
      </Link>
    )
  );
};

const Portfolio = () => {
  const { isAuthenticated } = useAuth0();
  return (
    isAuthenticated && (
      <Link exact to="/portfolio/">
        Portfolio
      </Link>
    )
  );
};

const WelcomeUser = () => {
  const { user, isAuthenticated } = useAuth0();
  return (
    isAuthenticated && (
      <div>
        <b>{user.name}</b>
      </div>
    )
  );
};

const Header = () => {
  // const { toggleDarkmode } = useStore();

  return (
    <div className="header">
      <div className="logo">
        <Link exact to="/">
          <h1>faavne.no</h1>
        </Link>
      </div>
      <div className="login-box">
        <>
          <WelcomeUser />
          <Profile />
          <Portfolio />
          <LoginButton />
          <LogoutButton />
        </>
      </div>
    </div>
  );
};

export default Header;
